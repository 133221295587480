<template>
  <v-row>
    <v-col
      cols="12"
      sm="6"
      md="6"
    >
      <v-data-table
        :headers="headers"
        :items="associations"
        :items-per-page="5"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>
              Association domaine d'intervention
            </v-toolbar-title>
            <v-spacer />
            <v-dialog
              v-model="dialog"
              max-width="500px"
            >
              <template v-slot:activator="{ on, attrs}">
                <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  Nouveau
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-select
                          v-model="AssociationEdit.id_domaine_intervention"
                          :items="ParamsDI"
                          item-text="libelle_parametrage_domaine_interventions"
                          item-value="id"
                          label="Domaine d'intervention"
                          single-line
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darke-1"
                    @click="close"
                  >
                    Fermer
                  </v-btn>
                  <v-btn
                    color="blue darke-1"
                    @click="saveAssociation"
                  >
                    Enregistrer
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog
              v-model="dialogDelete"
              max-width="500px"
            >
              <v-card>
                <v-card-title class="text-h5">
                  Are you sure you want to delete this item?
                </v-card-title>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="closeDelete"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="deleteItemConfirm"
                  >
                    OK
                  </v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>

        <template v-slot:item.actions="{item}">
          <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            small
            class="mr-2"
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import parametres from '@/services/parametre-domaine-intervention.js'
  import adminProjet from '@/services/admin-projets.js'
  import associationDI from '@/services/associations-domaine-intervention.js'
  export default {
    data () {
      return {
        dialog: false,
        dialogDelete: false,
        headers: [
          { text: 'Actions', value: 'actions', sortable: false },
          {
            text: 'Libellé projet',
            value: 'projets.libelle_projet',
          },
          {
            text: 'Libellé DI',
            value: 'parametrage_domaine_interventions.libelle_parametrage_domaine_interventions',
          },
        ],
        associations: [],
        ParamsDI: [],
        projects: [],
        editedIndex: -1,
        AssociationEdit: {
          id: '',
          id_domaine_intervention: '',
          id_projet: this.$route.params.project,
        },
        Association: {
          id_domaine_intervention: '',
          id_projet: this.$route.params.project,
        },
      }
    },
    mounted () {
      console.log(this.editedIndex)
      parametres.getParamsDI().then(response => {
        this.ParamsDI = response.data
      })
      adminProjet.getProjets().then(response => {
        this.projects = response.data
      }).catch(err => {
        console.log(err.data)
      })
      associationDI.getAssocitations().then(response => {
        this.associations = response.data
      }).catch(err => {
        console.log(err.data)
      })
    },
    computed: {
      ...mapGetters(['getThemeMode']),
      formTitle () {
        return this.editedIndex === -1 ? 'Ajouter une association' : 'Modifier une association'
      },
    },
    methods: {
      ...mapActions(['changeThemeLayout']),
      saveAssociation () {
        if (this.editedIndex > -1) {
          associationDI.updateAssocitation(this.AssociationEdit).then(response => {
            console.log(response.data)
            associationDI.getAssocitations().then(response => {
              this.associations = response.data
            })
            this.dialog = false
          }).catch(err => {
            console.log(err.data)
          })
        } else {
          associationDI.createAssocitation(this.AssociationEdit).then(response => {
            console.log(response.data)
            associationDI.getAssocitations().then(response => {
              this.associations = response.data
            })
            this.dialog = false
          }).catch(err => {
            console.log(err.data)
          })
        }
        this.close()
      },
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.AssociationEdit = Object.assign({}, this.associations)
          this.editedIndex = -1
        })
      },
      editItem (item) {
        this.editedIndex = this.associations.indexOf(item)
        this.AssociationEdit = Object.assign({}, item)
        this.dialog = true
      },
      deleteItem (item) {
        this.editedIndex = this.associations.indexOf(item)
        this.AssociationEdit = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        associationDI.deleteAssocitation(this.AssociationEdit).then(response => {
          associationDI.getAssocitations().then(response => {
            this.associations = response.data
          })
        }).catch(err => {
          console.log(err.data)
        })
        this.closeDelete()
      },
      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.AssociationEdit = Object.assign({}, this.Association)
          this.editedIndex = -1
        })
      },
    },
  }
</script>

<style scoped>

</style>
