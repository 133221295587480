import axios from 'axios'

const apiClient = axios.create({
  baseURL: 'https://web-api-sgp.piebarometre.digital/',
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + localStorage.getItem('token'),
  },
})

export default {
  getAssocitations () {
    return apiClient.get('api/request-parametre-intervention/list-projet-associer-domaine-intervention')
  },
  createAssocitation (data) {
   var formData = new FormData()
    formData.append('id_parametrage_domaine_interventions', data.id_domaine_intervention)
    formData.append('id_projets', data.id_projet)
    return apiClient.post('api/request-parametre-intervention/add-projet-associer-domaine-intervention', formData)
  },
  updateAssocitation (data) {
    var formData = new FormData()
    formData.append('id_parametrage_domaine_interventions', data.id_domaine_intervention)
    formData.append('id_projets', data.id_projet)
    return apiClient.post(`api/request-parametre-intervention/update-projet-associer-domaine-intervention/${data.id}`, formData)
  },
  deleteAssocitation (data) {
    return apiClient.post(`api/request-parametre-intervention/delete-projet-associer-domaine-intervention/${data.id}`)
  },
}
